import axios from "../../../utils/axios";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const getContacts = createAsyncThunk(
  "getContacts",
  async (data, thunkAPI) => {
    const response = await axios(
      process.env.REACT_APP_GTM +
        `contact/all?search=${data.search}&page=${data.page}&size=${data.size}`,
      {
        method: "GET",
        headers: {
          accept: "application/json,text/plain",
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, status: response.status };
  }
);

export const uploadFile = createAsyncThunk(
  "uploadFile",
  async (file, thunkAPI) => {
    const formData = new FormData();
    formData.append("file", file);
    console.log(formData, file);
    const response = await axios(process.env.REACT_APP_GTM + `fileupload/`, {
      method: "PUT",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
    return { data: response.data, status: response.status };
  }
);

export const insertContact = createAsyncThunk(
  "insertContact",
  async (file_uuid, thunkAPI) => {
    const response = await axios(
      process.env.REACT_APP_GTM + `contact/?file_uuid=${file_uuid}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
        },
        data: {},
      }
    );
    return { data: response.data, status: response.status };
  }
);
