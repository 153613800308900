import axios from "../../../utils/axios";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const getCampaigns = createAsyncThunk(
  "getCampaigns",
  async (data, thunkAPI) => {
    const response = await axios(
      process.env.REACT_APP_GTM +
        `campaign/?page=${data.page}&size=${data.size}`,
      {
        method: "GET",
        headers: {
          accept: "application/json,text/plain",
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, status: response.status };
  }
);

export const StopRunningCampaign = createAsyncThunk(
  "stopRunningCampaign",
  async (campaign_uuid, thunkAPI) => {
    const response = await axios(
      process.env.REACT_APP_GTM + `campaign/run?campaign_uuid=${campaign_uuid}`,
      {
        method: "DELETE",
      }
    );
    return { data: response.data, status: response.status };
  }
);

export const getAllEmailDomains = createAsyncThunk(
  "getAllEmailDomains",
  async (data, thunkAPI) => {
    const response = await axios(
      process.env.REACT_APP_GTM + `emaildomain/all`,
      {
        method: "GET",
        headers: {
          accept: "application/json,text/plain",
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, status: response.status };
  }
);

export const runCampaign = createAsyncThunk(
  "runCampaign",
  async (data, thunkAPI) => {
    const response = await axios(
      process.env.REACT_APP_GTM +
        `campaign/run?campaign_uuid=${data.campaign_uuid}&email_domain_uuid=${data.email_domain_uuid}`,
      {
        method: "POST",
        headers: {
          accept: "application/json,text/plain",
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, status: response.status };
  }
);

export const createCampaign = createAsyncThunk(
  "createCampaign",
  async (data, thunkAPI) => {
    await axios(process.env.REACT_APP_GTM + `campaign/`, {
      method: "POST",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
      data: {
        template_uuid: data.template_uuid,
        campaign_name: data.campaign_name,
        file_uuid: data.file_uuid,
      },
    });
    const response = await axios(
      process.env.REACT_APP_GTM +
        `campaign/?page=${data.page}&size=${data.size}`,
      {
        method: "GET",
        headers: {
          accept: "application/json,text/plain",
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, status: response.status };
  }
);
export const downloadSampleFile = createAsyncThunk(
  "downloadSampleFile",
  async (data, thunkAPI) => {
    const response = await axios(
      process.env.REACT_APP_GTM + `fileupload/sample`,
      {
        method: "GET",
        headers: {
          accept: "application/json,text/plain",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },

        responseType: "blob",
      }
    );
    if (response.headers["content-disposition"]) {
      const contentDisposition = response.headers["content-disposition"];
      const filenameMatch = contentDisposition.match(
        /filename\*=utf-8''(.+?)(;|$)/
      );
      if (filenameMatch) {
        const filename = decodeURIComponent(filenameMatch[1]);
        return { response, filename };
      }
    }

    return { response, filename: "default_filename.xlsx" };
  }
);
